import { createIconComponentFromSimpleIconsSvgPath, IntegrationConfig } from "@consent-manager/core"
import twitter from "simple-icons/icons/twitter"

export function twitterIntegration(): IntegrationConfig {
  const { title, hex, path } = twitter
  const color = `#${hex}`
  const contrastColor = "#fff"
  const Icon = createIconComponentFromSimpleIconsSvgPath(title, path)

  return {
    id: "twitter",
    title,
    category: "Social Media",
    color,
    contrastColor,
    Icon,
    privacyPolicyUrl: `https://twitter.com/en/privacy`,
    description: "Allow us to embed tweets to this website",
  }
}
