/**
 * This file integrates consent-manager to protect our visitors privacy
 * and supports us to align with GDPR and CCPA.
 *
 * Learn more: https://github.com/techboi/consent-manager
 */

import React from "react"

import createPersistedState from "use-persisted-state"

import { ConsentManagerDefaultInterface } from "@consent-manager/interface-default"
import "@consent-manager/interface-default/dist/default.min.css"
import { youtubeIntegration } from "@consent-manager/integration-youtube"
import { getMatomoTracker, matomoPrivacyAwareIntegration } from "@consent-manager/integration-matomo"
import {
  ConsentManagerConfig,
  ConsentManagerStore,
} from "@consent-manager/core"
import { twitterIntegration } from "./content-manager-twitter"

const useConsentStateStore = createPersistedState(
  `consent-manager`
)

const consentManagerConfig: ConsentManagerConfig = {
  integrations: [
    twitterIntegration(),
    youtubeIntegration(),
    matomoPrivacyAwareIntegration({
      matomoURL: "https://statistics.hashbite.net/",
      siteID: "19",
    }),
  ],
}

/**
 * Wraps the apps root element with consent-manager
 * See:
 * * https://github.com/techboi/consent-manager
 * * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapRootElement
 */
export function ConsentManagerWrapper({ children }) {
  const storage = useConsentStateStore() as ConsentManagerStore

  return (
    <ConsentManagerDefaultInterface
      config={consentManagerConfig}
      store={storage}
    >
      {children}
    </ConsentManagerDefaultInterface>
  )
}

/**
 * Called when the user changes routes, including on the initial load of the app
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onRouteUpdate
 */
export function onRouteUpdate({ location, prevLocation }) {
  const { trackPageViewSPA } = getMatomoTracker()

  // This ensures plugins like react-helmet finished their work
  window.setTimeout(() => {
    const trackResult = trackPageViewSPA({ location, prevLocation })

    // Debug logging
    if (process.env.gatsby_log_level === `verbose`) {
      const { url, title } = trackResult
      if (!trackResult) {
        return console.debug(
          `[Matomo] Failed to track page view: ${url} - ${title}`
        )
      }
      console.debug(`[Matomo] Page view for: ${url} - ${title}`)
    }
  }, 0)
}