/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react"
import propTypes from "prop-types"

import { ConsentManagerWrapper } from "./src/consent-manager"
export { onRouteUpdate } from "./src/consent-manager"

// Inject required contexts
export const wrapRootElement = ({ element }) => {
  return <ConsentManagerWrapper>{element}</ConsentManagerWrapper>
}

wrapRootElement.propTypes = {
  element: propTypes.element.isRequired,
}
